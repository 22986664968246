.pic_flex_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
}

.pic_flex_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
}

.pic_flex_center_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.pic_flex_center_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.pic_flex_align_items_start {
  align-items: flex-start;
}

.pic_max_height {
  /* border-radius: 16px; */
  min-height: 100%;
  min-width: 100%;
}

/*
  ----------------------------------------
  Dynamic
  ----------------------------------------
*/

@media screen and (min-width: 900px) {
  
  .pic_dynamic_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
  }

  .pic_dynamic_canvas_padding_large {
    padding: 4rem; 
  }

  .pic_dynamic_img {
    border-radius: 10px;
    max-width: 33vw !important;
    aspect-ratio: 4/3;
  }

  .pic_media_title_block {
    min-height: 50vh;
  }


  .pic_bar_dynamic_wrapper {
    height: 100%;
    min-height: 25vh;
  }

  .pic_bar_dynamic {
    border-radius: 16px;
    opacity: 0.85;
    min-height: 25vh;
  }
}

@media screen and (max-width: 899px) {

  .pic_dynamic_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
  }

  .pic_dynamic_canvas_padding_large {
    padding: 1rem;
  }

  .pic_dynamic_img {
    border-radius: 10px;
  }

  .pic_media_title_block {
    padding-top: 2rem;
    height: 100%;
  }

  .pic_bar_dynamic_wrapper {
    height: 100%;
    min-height: 25vh;
  }

  .pic_bar_dynamic {
    border-radius: 16px;
    opacity: 0.85;
    min-height: 25vh;
  }
}