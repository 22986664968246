/*
  ------------------------------------------
  BACKGROUND COLORS
  ------------------------------------------
*/

.pic_color_background_white {
  background-color: #ffffff;
}

.pic_color_background_primary {
  background-color: #006e90;
}

.pic_color_background_secondary {
  background-color: #99c24d;
}

.pic_color_background_tertiary {
  background-color: #f18f01;
}

.vkr_black_text {
  color: #333;
}

.vkr_black_text_background {
  background: #333;
}

.vkr_black_background {
  background: #333;
}

.vkr_white_text {
  color: #fff;
}

.vkr_white_text_background {
  background: #fff;
}

.vkr_white_background {
  background: #fff;
}

.vkr_one_text {
  color: #77c9d4;
}

.vkr_one_text_background {
  background: #77c9d4;
}

.vkr_one_background {
  background: #77c9d4;
}

.vkr_two_text {
  color: #57bc90;
}

.vkr_two_text_background {
  background: #57bc90;
}

.vkr_two_background {
  background: #57bc90;
}

.vkr_three_text {
  color: #015249;
}

.vkr_three_text_background {
  background: #015249;
}

.vkr_three_background {
  background: #015249;
}

.vkr_four_text {
  color: #a5a5af;
}

.vkr_four_text_background {
  background: #a5a5af;
}

.vkr_four_background {
  background: #a5a5af;
}