.pic_title_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 35px;
}

.pic_title_caption_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
}

.pic_padding_left_1 {
  padding-left: 1rem;
}

.pic_padding_left_2 {
  padding-left: 2rem;
}

.pic_padding_left_4 {
  padding-left: 4rem;
}

.pic_min_view_height_50vh {
  min-height: 50vh;
}

.pic_blank_padding {
  padding: 2rem;
}

.pic_padding_4 {
  padding: 4rem;
}

.pic_img {
  border-radius: 10px;
  max-width: 33vw !important;
  aspect-ratio: 4/3;
}