.vol_max_size {
  min-height: 100%;
  min-width: 100%;
}


/* LARGE SCREENS */
@media screen and (min-width: 900px) {
  .vol_canvas_padding {
    padding: 4rem;
  }
  .vol_mobile_bottom_padding {
  }
}

/* MOBILE */
@media screen and (max-width: 899px) {
  .vol_canvas_padding {
    padding: 1rem;
  }

  .vol_mobile_bottom_padding {
    padding-bottom: 2rem;
  }
}