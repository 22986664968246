
.vol_align_center {
  justify-content: center;
  align-items: center;
}

.vol_align_start {
  justify-content: flex-start;
  align-items: flex-start;
}

.vol_flex_column {
  display: flex;
  flex-direction: column;
}

.vol_justify_content_start {
  justify-content: flex-start;
}

/* LARGE SCREENS */
@media screen and (min-width: 900px) {
  .vol_flex_row {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
  }

  .vol_adjust_mobile {
    justify-content: center;
    align-items: center;
  }

  .vol_align_items_center_shift {
    align-items: center;
  }
}

/* MOBILE */
@media screen and (max-width: 899px) {
  .vol_flex_row {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .vol_adjust_mobile {
  }

  .vol_align_items_center_shift {
    align-items: flex-start;
  }

  .vol_padding_1_mobile {
    padding-left: 0.5rem;
  }
}