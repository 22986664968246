
.vol_title_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 35px;
}

.vol_subtitle_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
}

.vol_50_height {
  min-height: 50wh;
}

/* LARGE SCREENS */
@media screen and (min-width: 900px) {
  .vol_hero_img {
    border-radius: 10px;
    max-width: 33vw !important;
  }

  .vol_bar_wrapper {
    margin-left: 1rem;
    height: 100%;
    min-height: 25vh;
  }

  .vol_bar {
    border-radius: 16px;
    min-height: 25vh;
    min-width: 0.25rem;
  }
}

/* MOBILE */
@media screen and (max-width: 899px) {
  .vol_hero_img {
    border-radius: 10px;
  }

  .vol_bar_wrapper {
    padding-top: 1rem;
  }

  .vol_bar {
    display: none;
  }
}