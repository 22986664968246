.pic_background_cerulean {
  background-color: #006E90;
}

.pic_background_yellow_green {
  background-color: #006E90;
}


.pic_bar_wrapper {
  height: 100%;
  min-height: 25vh;
}

.pic_bar {
  border-radius: 16px;
  background-color: #ffffff;
  opacity: 0.85;
  min-height: 25vh;
}

.pic_bar_alt {
  border-radius: 16px;
  background-color: #05668D;
  opacity: 0.85;
  min-height: 25vh;
}