

/* PRIMARY */
.vol_background_primary {
  background-color: #006e90;
}

.vol_text_primary {
  color: #006e90;
}

/* SECONDARY */
.vol_background_secondary {
  background-color: #99c24d;
}

.vol_text_secondary {
  color: #99c24d;
}


/* TERTIARY */
.vol_background_tertiary {
  background-color: #f18f01;
}

.vol_text_tertiary {
  color: #f18f01;
}

/* WHITE */
.vol_background_white {
  background-color: #fff;
}

.vol_text_white {
  color: #fff;
}

/* BLACK */
.vol_background_black {
  background-color: #000;
}

.vol_text_back {
  color: #000;
}

.vol_opacity_85 {
  opacity: 0.85; 
}