

.pic_text_color_white {
  color: #ffffff;
}

.pic_text_color_black {
  color: #000000;
}

/* Blue */
.pic_text_color_primary {
  color: #05668D;
}

/* Green */
.pic_text_color_secondary {
  color: #679436;
}

/* Light Blue */
.pic_text_color_tertiary {
  color: #427AA1;
}

/* Light Green */
.pic_text_color_quaternary {
  color: #A5BE00;
}

/* Lighter Blue */
.pic_text_color_quinary {
  color: #EBF2FA;
}

.pic_text_color_white_background {
  background: #ffffff;
}

.pic_text_color_black_background {
  background: #000000;
}

/* Blue */
.pic_text_color_primary_background {
  background: #05668D;
}

/* Green */
.pic_text_color_secondary_background {
  background: #679436;
}

/* Light Blue */
.pic_text_color_tertiary_background {
  background: #427AA1;
}

/* Light Green */
.pic_text_color_quaternary_background {
  background: #A5BE00;
}

/* Lighter Blue */
.pic_text_color_quinary_background {
  background: #EBF2FA;
}